exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-[slug]-tsx": () => import("./../../../src/pages/blog/[slug].tsx" /* webpackChunkName: "component---src-pages-blog-[slug]-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-case-tsx": () => import("./../../../src/templates/Case.tsx" /* webpackChunkName: "component---src-templates-case-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/ContactUs.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-custom-tsx": () => import("./../../../src/templates/Custom.tsx" /* webpackChunkName: "component---src-templates-custom-tsx" */),
  "component---src-templates-ecommerce-tsx": () => import("./../../../src/templates/Ecommerce.tsx" /* webpackChunkName: "component---src-templates-ecommerce-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-investors-tsx": () => import("./../../../src/templates/Investors.tsx" /* webpackChunkName: "component---src-templates-investors-tsx" */),
  "component---src-templates-marketplace-tsx": () => import("./../../../src/templates/Marketplace.tsx" /* webpackChunkName: "component---src-templates-marketplace-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/Portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-saas-tsx": () => import("./../../../src/templates/Saas.tsx" /* webpackChunkName: "component---src-templates-saas-tsx" */),
  "component---src-templates-terms-and-conditions-tsx": () => import("./../../../src/templates/TermsAndConditions.tsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-tsx" */)
}

